import React from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import {callApiUrl} from './utils/functions'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { setLocation, hideNotification } from './utils/store'
import store from './utils/store'

import LocationScreen from './pages/Location'
import About from './pages/About'
import Checkout from './pages/Checkout'
import Track from './pages/Track'

import './App.scss';
import "./fontello/css/fontello.css"

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {

  let path = useLocation();
  const cart = useSelector(state => state.cart);
  const location = useSelector(state => state.location);
  const isLoadingLocation = useSelector(state => state.isLoadingLocation);
  const cart_notification = useSelector(state => state.cart_notification);
  const dispatch = useDispatch();

  const closeCartNotification = () => {
    dispatch(hideNotification());
    
  }

  React.useEffect(() => {
    
    let apiUrl = process.env.REACT_APP_API_URL + '/location/get/' + process.env.REACT_APP_LOCATION_ID + '/static';
            
    callApiUrl('GET', apiUrl)
    .then(function (response) {
      dispatch(setLocation(response.location));
    })
    .catch(function (err) {

    });
  }, []);

  function CheckoutForm() {
    return <Elements stripe={stripePromise}><Checkout /></Elements>
  }

  let location_logo = '';
  if (location.img_logo){
    location_logo = process.env.REACT_APP_ASSETS_URL + location.img_logo;
  }
  
  return (
      <div className="App">
        <nav className="navbar is-fixed-top is-spaced">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                {
                isLoadingLocation ? (
                    <p className="placeholder-item" style={{minWidth: '70px', height: '2em'}}></p>
                ) : (
                  <img src={location_logo} />
                )
                }
              </Link>
            </div>
            <div className="navbar-end">
              <div className="buttons">
                <div className="navbar-item">
                  <Link className={`button is-primary ${path.pathname == '/' ? "" : "is-light"}`} to="/"><i className="icon-food" /><span className="is-hidden-mobile">&nbsp;Menu</span></Link>
                </div>
                <div className="navbar-item">
                  <Link className={`button is-primary ${path.pathname == '/about' ? "" : "is-light"}`} to="/about"><i className="icon-info" /><span className="is-hidden-mobile">&nbsp;À Propos</span></Link>
                </div>
                <div className="navbar-item">
                  <Link className={`button is-primary ${path.pathname == '/checkout' ? "" : "is-light"}`} to="/checkout"><i className="icon-basket" /><span className="is-hidden-mobile">&nbsp;Ma commande&nbsp;</span>({cart.count})</Link>
                </div>
              </div>

            </div>
          </div>
        </nav>
        <div id="cart-notification" className={`notification is-success ${cart_notification ? "is-active" : ""}`}>
            <button onClick={closeCartNotification} className="delete"></button>
            <span id="notification-message">Item ajout&eacute; au panier</span>
        </div>
        <div className="container">
          <div className="box-content">
            <Routes>
              <Route path="/" element={<LocationScreen />} />
              <Route path="about" element={<About />} />
              <Route path="checkout" element={<CheckoutForm />} />
              <Route path="track/:invoiceNumber" element={<Track />} />
            </Routes>
          </div>
        </div>
        <div style={{marginTop: '3em',paddingBottom: '2em', paddingLeft: '0.5em', paddingRight: '0.5em'}}>
          <p className="has-text-centered"><a href="https://www.onship.ca" style={{textDecoration: 'underline'}}>Propulsé par OnShip.ca</a></p>
        </div>
      </div>
  );
}

const AppWrapper = () => {

  return (
    <Provider store={store}> 
      <App />
    </Provider>
  )
}

export default AppWrapper;
